<template>
    <DashboardContainer>
        <Templates/>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../containers/DashboardContainer'
import Templates from '../components/templates/Templates'

export default {
    name: 'etemplate',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')
            }
        }
    },
    components: {
        DashboardContainer,
        Templates
    }
}
</script>