<template>
  <div>
    <div class="email-template">
      <div class="row">
        <div class="col-md-3" v-if="temp_.ut_content">
          <div
            class="nav flex-column nav-pills"
            role="tablist"
            aria-orientation="vertical"
          >
            <a
              v-for="temp in email_templates"
              :key="temp.ut_id"
              v-on:click.stop.prevent="temp_ = temp"
              class="nav-link"
              v-bind:class="temp_.ut_id == temp.ut_id ? 'active' : ''"
              href="#"
              role="tab"
              >{{ temp.ut_title }}</a
            >
            <router-link to="/add-template" class="nav-link"
              >+ Add New Template</router-link
            >
          </div>
        </div>
        <div class="col-md-9" v-if="temp_.ut_content">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="v-pills-touch-tab"
            >
              <div class="email-boxs">
                <editor
                  v-model="temp_.ut_content"
                  :api-key="tinykey"
                  :init="editorConfig"
                ></editor>
                <br /><br />
                <div class="form-group add-member add-lead">
                  <label>Subject</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="temp_.ut_subject"
                  />
                </div>
                <div class="pt-3 pb-3">
                  <span style="font-weight: 700">Variables:</span>
                  <a class="add-btn" v-on:click="addKeyword('%CUSTOMER_NAME%')"
                    >%CUSTOMER_NAME%
                  </a>
                  <a class="add-btn" v-on:click="addKeyword('%DEALER_NAME%')"
                    >%DEALER_NAME%
                  </a>
                  <a class="add-btn" v-on:click="addKeyword('%VEHICLE_MAKE%')"
                    >%VEHICLE_MAKE%
                  </a>
                  <a class="add-btn" v-on:click="addKeyword('%VEHICLE_MODEL%')"
                    >%VEHICLE_MODEL%
                  </a>
                  <a class="add-btn" v-on:click="addKeyword('%VEHICLE_YEAR%')"
                    >%VEHICLE_YEAR%
                  </a>
                  <a class="add-btn" v-on:click="addKeyword('%VEHICLE_TRIM%')"
                    >%VEHICLE_TRIM%</a
                  >
                  <a class="add-btn" v-on:click="addKeyword('%SALES_PERSON%')"
                    >%SALES_PERSON%</a
                  >
                  <a class="add-btn" v-on:click="addKeyword('%BDC%')">%BDC%</a>
                  <a
                    class="add-btn"
                    v-on:click="addKeyword('%SALES_PERSON_EMAIL_SIGNATURE%')"
                    >%SALES_PERSON_EMAIL_SIGNATURE%</a
                  >
                </div>
                <div class="modal-footer email-footer">
                  <a
                    href="#"
					style="background:#ff4343;color:white;border:red"
                    v-on:click.stop.prevent="deleteTemplate()"
                    class="add-btn"
                  >
                    Delete</a
                  >
                  <a href="#" @click="updateTitleModal" class="rem-btn">
                    Update Template Name</a
                  >
                  <a
                    href="#"
                    v-on:click.stop.prevent="updateTemplate()"
                    class="rem-btn"
                  >
                    Save</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!temp_.ut_content && loaded" class="no-template col-12">
          <h4>No Template Found</h4>
          <p>Please create your first email template.</p>
          <router-link to="/add-template" class="rem-btn"
            ><img
              class="img-fluid"
              src="../../assets/img/23. Close.svg"
              alt=""
            />
            Add New Template</router-link
          >
        </div>
      </div>
    </div>
    <!-- Update Tile  -->
    <div
      class="modal fade"
      id="updateTitle"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Update Template Title
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="updateTitle" autocomplete="off">
            <div class="modal-body">
              <div>
                <div class="add-member add-lead">
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="temp_.ut_title"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Update Tile  -->
  </div>
</template>

<script>
import axios from "axios";
// import CKEditor from "ckeditor4-vue";
import Editor from "@tinymce/tinymce-vue";
import { tinymcekey } from "../../config.js";
import $ from "jquery";
export default {
  name: "Templates",
  components: { editor: Editor },
  data() {
    return {
      loaded: false,
      email_templates: [],
      tinykey: tinymcekey,
      temp_: { ut_content: "", title: "" },
      editorConfig: {
        element_format: "html",
        relative_urls: false,
        remove_script_host: false,
        menubar: false,
        height: 300,
        branding: false,
        plugins: [
          "advlist autolink lists link image media charmap print preview anchor",
          "searchreplace visualblocks code",
        ],
        toolbar:
          "bold italic | alignleft aligncenter alignright | bullist numlist | link | image |media| code | formatselect",
        images_upload_handler: function (blobInfo, success, failure, progress) {
          var xhr, formData;

          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          //console.log(axios.defaults.baseURL+'tinyImageUpload')
          xhr.open("POST", axios.defaults.baseURL + "emialSignatureImage");

          xhr.upload.onprogress = function (e) {
            progress((e.loaded / e.total) * 100);
          };

          xhr.onload = function () {
            var json;

            if (xhr.status === 403) {
              failure("HTTP Error: " + xhr.status, { remove: true });
              return;
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              failure("HTTP Error: " + xhr.status);
              return;
            }

            json = JSON.parse(xhr.responseText);

            if (!json || typeof json.location != "string") {
              failure("Invalid JSON: " + xhr.responseText);
              return;
            }

            success(json.location);
          };

          xhr.onerror = function () {
            failure(
              "Image upload failed due to a XHR Transport error. Code: " +
                xhr.status
            );
          };

          formData = new FormData();
          formData.append("file", blobInfo.blob(), blobInfo.filename());

          xhr.send(formData);

          //console.log(blobInfo.base64())
          //console.log(success)
          //console.log(failure)
        },
      },
    };
  },
  created() {
    this.getTemplates();
    this.getEmailSignature();
  },
  methods: {
    updateTitleModal() {
      if (this.temp_.ut_id) {
        $("#updateTitle").modal("show");
      }
    },
    updateTitle() {
      axios
        .post("update_template/title", this.temp_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.$toast.open({
              position: "top-right",
              message: "Template Title Updated",
            });
            $("#updateTitle").modal("hide");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEmailSignature() {
      axios
        .get("get/email/signature", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.status) {
            window.tinymce.activeEditor.setContent(res.data.signature);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onEditorReady(CKEDITOR) {
      this.editor = CKEDITOR;
    },
    addKeyword(keyword) {
      //this.editor.insertText(keyword);
      window.tinymce.activeEditor.execCommand(
        "mceInsertContent",
        true,
        keyword
      );

      //this.temp_.ut_content += keyword
    },
    getTemplates() {
      axios
        .get("get_templates/1", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.email_templates = res.data.success;
            let vm = this;
            setTimeout(() => {
              if (vm.email_templates.length > 0) {
                vm.temp_ = vm.email_templates[0];
              }
              this.loaded = true;
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateTemplate() {
      if (this.temp_.ut_id) {
        axios
          .post("update_template", this.temp_, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              alert(res.data.success);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteTemplate() {
      if (this.temp_.ut_id) {
        this.$confirm({
          title: `Are you sure?`,
          message: "You want to delete this template.",
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              axios
                .post("delete_template", this.temp_, {
                  headers: {
                    Authorization: "Bearer " + this.$storage.get("auth").token,
                  },
                })
                .then((res) => {
                  if (res.data.success) {
                    if (this.email_templates.length == 1) {
                      this.temp_ = { ut_content: "" };
                    }

                    this.getTemplates();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.no-template {
  height: 300px;
  text-align: center;
  padding: 80px 30px 30px;
}
.no-template h4 {
  padding-bottom: 10px;
  color: #7b7b7b;
}
.no-template p {
  padding-bottom: 30px;
  color: #9c9c9c;
}
.no-template a {
  padding-bottom: 10px;
  display: inline-block;
}
</style>
<style>
.pb-3 .add-btn {
  text-decoration: none;
  display: inline-block;
  border: 1px solid #a2a8b8;
  padding: 8px 25px;
  border-radius: 30px;
  text-transform: capitalize;
  line-height: 1;
  color: #6d778e;
  font-size: 14px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-weight: normal;
  align-items: center;
  padding: 8px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
</style>